<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'App',
}
</script>
<style>
  [v-cloak]{display: none;}
  *{
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Regular, sans-serif;
  }
  html{
    font-size:16px
  }
  #app{
    /* min-width: 1200px; */
    min-width: 1432px;
    width:100%;
  }
  @font-face {
    font-family: pingfang;
    src: url('./assets/font/PingFangSCRegular.eot');
	  src:local('☺'),
		url('./assets/font/PingFangSCRegular.eot?#iefix') format('embedded-opentype'),
		url('./assets/font/PingFangSCRegular.woff') format('woff'),
		url('./assets/font/PingFangSCRegular.ttf') format('truetype'),
		url('./assets/font/PingFangSCRegular.svg#webfontOTINA1xY') format('svg');
		font-weight:normal;
		font-style:normal;
  }
</style>
