import * as user from './user'
import * as browser from './browser'
import * as screen from './screen'
import * as news from './news'

export default {
  news,
  user,
  browser,
  screen
}
