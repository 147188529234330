import Vue from 'vue'
import Router from 'vue-router'
import router from '@/router'
import store from '@/store'
import { getStorageItem } from '@/utils'
import { projectTitle } from '@/config'

const Login = () => import('@/views/Login.vue')
const Index = () => import('@/views/Index.vue')
const Home = () => import('@/views/indexPage/IndexPage.vue') // 首页
const CoreBusiness = () => import('@/views/corebusiness/CoreBusiness.vue') // 核心业务
const AboutUs = () => import('@/views/aboutUs/AboutUs.vue') // 关于我们
const ComDynamics = () => import('@/views/comDynamics/ComDynamics.vue') // 公司动态
const PersonTrain = () => import('@/views/perSonTrain/PerSonTrain') // 人才培养
const Solution = () => import('@/views/solution/Solution') // 多元解纷
const  SocialGovernance= () => import('@/views/corebusiness/SocialGovernance.vue') //社会治理
const  DynamicDetail= () => import('@/views/comDynamics/DynamicDetail') //社会治理
Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      children:[
        {
          path: '/',
          name: 'Home',
          component: Home,
        },
        {
          path: '/home',
          name: 'Home',
          component: Home,
        },
        {
          path: '/coreBusiness/SocialGovernance',
          name: 'SocialGovernance',
          component: SocialGovernance,
        },
        {
          path: '/AboutUs',
          name: 'AboutUs',
          component: AboutUs,
        },
        {
          path: '/ComDynamics',
          name: 'ComDynamics',
          component: ComDynamics,
        },
        {
          path: '/DynamicDetail',
          name: 'DynamicDetail',
          component: DynamicDetail,
        },
        {
          path: '/PersonTrain',
          name: 'PersonTrain',
          component: PersonTrain,
        },
        {
          path: '/Solution',
          name: 'Solution',
          component: Solution,
        },
        {
          path: '/Solution',
          name: 'Solution',
          component: PersonTrain,
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    }
  ],
  scrollBehavior(to,from,savedPosition){
    return{
      x:0,
      y:0
    }
  }
})

// 路由钩子,导航之前
// router.beforeEach(async (to, from, next) => {
//   // Loading.service() // 开启页面loging状态
//   const userId = getStorageItem('userId')
//   // 防止刷新页面之后用户信息丢失
//   if (userId && !store.state.user) {
//     await store.dispatch('fetchUserBeforeRoute', userId)
//   }
//   // 判断目的路由是否需要登陆授权
//   if (to.matched.some(record => record.meta.requireAuth) && !userId) {
//     next({
//       name: 'login',
//       query: {
//         redirect: to.fullPath // 登陆之后需要重定向的路由路径
//       }
//     })
//   } else {
//     next()
//   }
// })

// 进入路由后设置文档标题
router.afterEach((to) => {
  document.title = "枫调理顺-社会治理与纠纷解决方案商"
})
