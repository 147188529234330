import axios from '@/axios'
// 每日存证数量统计 api/v1/zxl_stat/chain/evidence
export const fetchEvidence = (data) => axios.get(`/zxl_stat/chain/evidence?range=${data.range}&type=${data.type}`)

// 节点分布图 GET api/v1/zxl_stat/chain/node_distribute
export const fetchNodeDistribute = () => axios.get('/zxl_stat/chain/node_distribute')


// 总体概览(出块时间-->每分钟出块多少个[腾讯提供])  api/v1/zxl_stat/chain/chain_state
export const fetchChainState = () => axios.get('/zxl_stat/chain/chain_state')


// 业务并发量 GET api/v1/zxl_stat/chain/concurrent 输入参数:
export const fetchConcurrent = () => axios.get('/zxl_stat/chain/concurrent')


// 出块时间统计(腾讯提供) :GET api/v1/zxl_stat/chain/block_time
export const fetchBlockTime = () => axios.get('/zxl_stat/chain/block_time')


//节点状态监控  GET api/v1/zxl_stat/chain/node_info
export const fetchNodeInfo = () => axios.get('/zxl_stat/chain/node_info')
