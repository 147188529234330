import "babel-polyfill"; // 兼容
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import './element-ui'
import moment from "moment";
Vue.prototype.$moment = moment;

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// import echarts from 'echarts'
// import 'lib-flexible'
// 开发环境启动Mock;
process.env.NODE_ENV === 'development' && import('@/mock')
// 挂载自定义axios实例在Vue的原型链上，使用时使用this.$axios(...)
Vue.prototype.$axios = axios
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // 这里的render-event是要和下面配置的vue.config.js里该插件声明的保持一致
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')
